import { Address, ContractFunction, ProxyProvider, SmartContract, TypedValue } from "@elrondnetwork/erdjs/out";
import { smartContractAddress, network } from "../config";

const fetchQueryHex = (method: string, args: TypedValue[]) => new Promise<string>(async (resolve, reject) => {
    let contract = new SmartContract({ address: new Address(smartContractAddress) });
    let provider = new ProxyProvider(network.gatewayAddress || "");
    let response = await contract.runQuery(provider, {
        func: new ContractFunction(method),
        args: args
    });
    if (response.isSuccess()) {
        if (response.returnData !== undefined) {
            const [encoded] = response.returnData;
            if (!encoded)
                resolve("-1");
            const decoded = Buffer.from(encoded || "", "base64").toString("hex");
            resolve(decoded);
        }
    }
    else {
        reject("Something went wrong");
    }
});

export default fetchQueryHex;