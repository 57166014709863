import React, {useEffect} from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ContextProvider } from "./context";
import * as config from "./config";
import Layout from "./components/Layout";
import routes, { routeNames } from "./routes";
import PageNotFound from "./components/PageNotFoud";
import { Helmet } from "react-helmet";
import Aos from 'aos';
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {

    Aos.init({
      duration: 1000
    })
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>
          NFT Marketplace
        </title>
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet" />
        <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
      </Helmet>
      <Router basename={process.env.PUBLIC_URL}>
        <ContextProvider>
          <Dapp.Context
            config={config}
          >
            <Layout>
              <Switch>
                <Route
                  path="/ledger" /* must correspond to ledgerRoute */
                  component={() => (
                    <Dapp.Pages.Ledger callbackRoute={routeNames.home} />
                  )}
                  exact={true}
                />
                <Route
                  path="/walletconnect" /* must correspond to walletConnectRoute */
                  component={() => (
                    <Dapp.Pages.WalletConnect
                      callbackRoute={routeNames.home}
                      logoutRoute={routeNames.home} /* redirect after logout */
                      title="Maiar Login"
                      lead=""
                    />
                  )}
                  exact={true}
                />

                {routes.map((route, i /* rest of routes */) => (
                  <Route
                    path={route.path}
                    key={route.path + i}
                    component={route.component}
                    exact={true}
                  />
                ))}
                <Route component={PageNotFound} />
              </Switch>
            </Layout>
          </Dapp.Context>
        </ContextProvider>
      </Router>
    </div>
  );
}

export default App;
