import * as Dapp from "@elrondnetwork/dapp";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RawTransactionType } from "../../../../helpers/types";
import useNewTransaction from "../../../Transaction/useNewTransaction";
import { smartContractAddress } from "../../../../config";
import fetchQueryHex from "../../../../helpers/scQueries";

const BuyNFTOrRedirect = () => {
    const [nftPrice, setNftPrice] = useState(0.25);
    const { loggedIn } = Dapp.useContext();
    const history = useHistory();
    const sendTransaction = Dapp.useSendTransaction();
    const newTransaction = useNewTransaction();

    const buyNftTransaction: RawTransactionType = {
        receiver: smartContractAddress,
        value: nftPrice.toString(),
        gasLimit: 600000000,
        data: "buyNft"
    };

    const send = (transaction: RawTransactionType) => (e: React.MouseEvent) => {
        e.preventDefault();
        sendTransaction({
            transaction: newTransaction(transaction),
            callbackRoute: "/transaction"
        });
    }

    useEffect(() => {
        fetchQueryHex("getNftPrice", [])
            .then((data) => {
                let parsedPrice = parseInt(data, 16);
                setNftPrice(parsedPrice / 1_000_000_000_000_000_000);
            })
    }, []);

    return loggedIn
        ? <button className="btn btn-explore more-btn mr-3" onClick={send(buyNftTransaction)}>Summon your Goddess</button>
        : <button className="btn btn-explore more-btn mr-3" onClick={() => history.push("/connect")}>Summon your Goddess</button>
}

export default BuyNFTOrRedirect;