import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { dAppName } from "./config";
import withPageTitle from "./components/PageTitle";
import Home from "./pages/Home";
import Transaction from "./pages/Transaction";
import WalletConnect from "./pages/WalletConnect";

type RouteType = Dapp.RouteType & { title: string };

export const routeNames = {
  home: "/",
  transaction: "/transaction",
  connect: "/connect"
};

const routes: RouteType[] = [
  {
    path: routeNames.home,
    title: "Home",
    component: Home,
  },
  {
    path: routeNames.transaction,
    title: "Transaction",
    component: Transaction,
  },
  {
    path: routeNames.connect,
    title: "Connect",
    component: WalletConnect,
  },
];

const wrappedRoutes = () =>
  routes.map((route) => {
    const title = route.title
      ? `${route.title} • ${dAppName}`
      : `${dAppName}`;
    return {
      path: route.path,
      authenticatedRoute: Boolean(route.authenticatedRoute),
      component: withPageTitle(
        title,
        route.component,
      ) as any as React.ComponentClass<any, any>,
    };
  });

export default wrappedRoutes();
