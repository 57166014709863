import ConnectWalletIconswallet from "../../assets/img/icons/wallet.png"
import ConnectView from "./ConnectView";

const InfoComponent = () => {
    return (
        <div className="section-heading text-center">
            <img src={ConnectWalletIconswallet} className="mb-15" width="90" alt="" data-aos="fade-up" data-aos-delay='200'/>

            <div className="dream-dots justify-content-center" data-aos="fade-up" data-aos-delay='250'>
                <span>Connect your wallet</span>
            </div>
            <div data-aos="fade-up" data-aos-delay="350">
                <ConnectView />
            </div>
        </div>
    )
}

export default InfoComponent