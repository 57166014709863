import React from "react";
import * as Dapp from "@elrondnetwork/dapp";
import { Navbar as BsNavbar, NavItem, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link, NavLink, useHistory } from "react-router-dom";
import { dAppName } from "../../../config";
import { routeNames } from "../../../routes";
import Preloader from "../../Preloader";

const Navbar = () => {
  const { loggedIn } = Dapp.useContext();
  const dappLogout = Dapp.useLogout();
  const history = useHistory();

  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    dappLogout({ callbackUrl: `${window.location.origin}${routeNames.connect}` });
    history.push(routeNames.connect);
  };

  return (
    <>
        <Preloader Title={dAppName} />
        <nav className="navbar navbar-expand-lg navbar-white fixed-top" id="banner" style={{backgroundColor: "rgb(17,18,34)"}}>
            <div className="container">
                <NavLink className="navbar-brand" to={routeNames.home}><span><img src="/tmg-logo.png" alt="logo" width="60px"/> The Maiar Goddesses</span></NavLink>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav ml-auto">
                        {loggedIn
                            ? <li className="lh-55px"><NavLink to={routeNames.home} className="btn login-btn ml-50" onClick={logOut} >Disconnect</NavLink></li>
                            : <li className="lh-55px"><NavLink to={routeNames.connect} className="btn login-btn ml-50">Connect Wallet</NavLink></li>
                        }
                    </ul>
                </div>
            </div>
        </nav>
    </>
  );
};

export default Navbar;
