import * as Dapp from "@elrondnetwork/dapp";
import { useLocation } from "react-router-dom";
import { routeNames } from "../../routes";
const ConnectView = () => {
    const { search } = useLocation();

    const query = new URLSearchParams(search);
    const { type } = Object.fromEntries(query);

    switch (type) {
        case "ledger":
            return <Dapp.Pages.Ledger callbackRoute="/" />;
        case "maiar":
            return <Dapp.Pages.WalletConnect
                callbackRoute={routeNames.home}
                logoutRoute={routeNames.home}
                title="Maiar Login"
                lead="Scan the QR code using Maiar"
            />;
        default:
            return <Dapp.Pages.Unlock
                callbackRoute={routeNames.home}
                title=""
                lead=""
                ledgerRoute="/connect?type=ledger"
                walletConnectRoute="/connect?type=maiar"
            />;
    }
}

export default ConnectView;