import HeroContainer from "./containers/HeroContainer";
import CollectionDetailsContainer from "./containers/CollectionDetailsContainer";

const Home = () => {
  const extraMargin = {
    marginBottom: "90px"
  };

  const logoStyling = {
    maxWidth: "100%"
  };

  return <>
    	  <HeroContainer />
        <CollectionDetailsContainer />
    </>
};

export default Home;
