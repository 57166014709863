import InfoComponent from "./FunctionComponent";

const WalletConnect = () => {
    return <section className="features section-padding-100 ">

    <div className="container">
        <InfoComponent />
    </div>
</section>
}

export default WalletConnect;