import * as Dapp from "@elrondnetwork/dapp";
import BuyNFTOrRedirect from "./BuyNFTOrRedirect";

const HeroContainer = () => {
	const {loggedIn} = Dapp.useContext();
	return(
	    <section className="hero-section moving section-padding" id="home">
	        <div className="hero-section-content">	            
	            <div className="container ">
	                <div className="row align-items-center">
	                    <div className="col-12 col-lg-6 col-md-12">
	                        <div className="welcome-content">
	                            <div className="promo-section">
	                                <h3 className="special-head gradient-text">About</h3>
	                            </div>
	                            <h1>Welcome to the sacred temple of the <span className="gradient-text">Maiar Goddesses</span> </h1>
	                            <p className="w-text">The Maiar Goddesses NFT collection consists of 1880 Goddesses, each having their own individual traits. No one Goddesses alike. The unique goddess characters were inspired by the oldest legends of elves and ancient spirits from unknown times and mark the fusion between blockchain technology, modern art, and ancient mythology. The Maiar Goddesses caught up with the times and now reside on the Elrond Network Blockchain while calling the Maiar Wallet their home!!! – summon your goddess and get ready for a long journey with <b className="gradient-text">KosmiumNFT</b>!</p>
	                            <div className="dream-btn-group">
									<BuyNFTOrRedirect />
	                            </div>
	                        </div>
	                    </div>
	                    <div className="col-lg-6"></div>
	                </div>
	            </div>
	        </div>
	    </section>
	)
}

export default HeroContainer