import React, { useEffect, useState } from "react";
import * as config from "../../../../config";
import fetchQueryHex from "../../../../helpers/scQueries";

function CollectionDetailsContainer() {
    const [didMount, setDidMount] = useState(false);
    const [remainingSupply, setRemainingSupply] = useState(0);
    const [price, setPrice] = useState(0);

    useEffect(() => {
        if (didMount) {
            return;
        }
        Promise.all([
            fetchQueryHex("remaining", []),
            fetchQueryHex("getNftPrice", [])
        ])
            .then(([data, nftPrice]) => {
                setRemainingSupply(parseInt(data, 16));

                let parsedPrice = parseInt(nftPrice, 16);
                setPrice(parsedPrice / 1_000_000_000_000_000_000);
            });
        setDidMount(true);
    }, []);


    return (
        <section className="section-padding-100 clearfix" >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="promo-section">
                            <h3 className="special-head gradient-text">How to summon your own Goddesses?</h3>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-12 text-white" data-aos-delay={1000} data-aos="fade-up">
                        <p>Well.. in the best possible way, more exactly, you will be able to mint your NFTs through a custom smart contract on our website. Connect your wallet to buy an Maiar Goddess using EGLD. So simple!</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="service_single_content collection-item" data-aos-delay={300} data-aos="fade-up" style={{ backgroundColor: "#ff00ff" }}>
                            <div className="w-text d-flex justify-content-center mt-5">
                                <h3 style={{ color: "#04091E" }}><b>{remainingSupply}</b></h3>
                            </div>
                            <div className="collection_info">
                                <h6 style={{ color: "#03091f" }}>Remaining Supply</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="service_single_content collection-item" data-aos-delay={500} data-aos="fade-up" style={{ backgroundColor: "#ff00ff" }}>
                            <div className="w-text d-flex justify-content-center mt-5">
                                <h3 style={{ color: "#04091E" }}><b>{price} EGLD</b></h3>
                            </div>
                            <div className="collection_info">
                                <h6 style={{ color: "#03091f" }}>Price</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="service_single_content collection-item" data-aos-delay={600} data-aos="fade-up" style={{ backgroundColor: "#ff00ff" }}>
                            <div className="w-text d-flex justify-content-center mt-5">
                                <h3 style={{ color: "#04091E" }}><b>12 Dec</b></h3>
                            </div>
                            <div className="collection_info">
                                <h6 style={{ color: "#03091f" }}>Date</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="service_single_content collection-item" data-aos-delay={700} data-aos="fade-up" style={{ backgroundColor: "#ff00ff" }}>
                            <div className="w-text d-flex justify-content-center mt-5">
                                <h3 style={{ color: "#04091E" }}><b>Elrond</b></h3>
                            </div>
                            <div className="collection_info">
                                <h6 style={{ color: "#03091f" }}>Network</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CollectionDetailsContainer