import { smartContractAddress } from "../../../config";
const Footer = () => {
  const socialLogoStyle = {
    width: "auto",
    height: "auto",
    maxHeight: "50px",
    marginRight: "15px",
    marginLeft: "15px"
  };
  return (
    <footer className="d-flex justify-content-center mt-2 mb-3 text-muted footer-text">
      <a href={`https://explorer.elrond.com/accounts/${smartContractAddress}`} target="_blank"><img src="/social/egld-logo.jpeg" style={socialLogoStyle}/></a>
      <a href="https://discord.gg/CftpJum6HZ" target="_blank"><img src="/social/discord.png" style={socialLogoStyle}/></a>
      <a href="https://t.me/WrN5QYgNveNlM2Y0" target="_blank"><img src="/social/telegram.png" style={socialLogoStyle}/></a>
      <a href="https://twitter.com/KosmiumNFT" target="_blank"><img src="/social/twitter.png" style={socialLogoStyle}/></a>
      <a href="https://www.instagram.com/invites/contact/?i=15ozbsglqlmc7&utm_content=n9ntgit" target="_blank"><img src="/social/instagram.png" style={socialLogoStyle}/></a>
    </footer>
  );
};

export default Footer;